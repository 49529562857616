body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-color: #2c2f30 !important;*/
    background: #2c2f30 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[aria-label='Select dropdown'] div div > svg {
    fill: #e1003c;
    stroke: #e1003c;
}

button#data--filters-control div div div {
    background-color: #e1003c;
}

th div#data div div button {
    border-color: #e1003c;
    color: #ffffff;
    font-size: 14px;
}

th div#data div div button:hover {
    box-shadow: none;
}

.tdc-table {
    background-color: #e0e0db;
    block-size: 100%;
    border: none;
}


.tdc-table .rdg-header-row,
.tdc-table .rdg-summary-row {
    background-color: #006485 !important;
    color: #e0e0db !important;
    --rdg-summary-row-top: 0 !important;
}

.tdc-table .rdg-cell:has(:first-child.grouped-cell-alt) {
padding: 0;
}
.tdc-table .rdg-header-row .rdg-cell {
    align-items: center;
    display: flex;
    padding-block-start: 0 !important;
    text-align: center;
    justify-content: center;
    border-color: #e0e0db !important;
}

.tdc-table div[aria-rowspan='2'] .header-wrapper {
    padding-block-start: calc(1 * 40px);
}

.tdc-table .parent-col {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2px;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.tdc-table .rdg-header-row .rdg-cell-resizable div:last-child:hover {
    background: #e1003c;
}

.tdc-table .table-row .rdg-cell {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: #e0e0db;
    color: #2c2f30;
}

.tdc-table.ProjectComment .table-row .rdg-cell {
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*-webkit-line-clamp: 3; !* Limits to 3 lines *!*/
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.tdc-table .table-row .rdg-cell p {
    color: #2c2f30;
}

.tdc-table .table-row .rdg-cell:hover p {
    color: #e0e0db;
}

.tdc-table .select-header,
.tdc-table .select-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-row:hover {
    cursor: default;
}

.table-row:hover,
.table-sub-row:hover,
.table-row:hover .sticky,
.tdc-table .table-row:hover .rdg-cell {
    background-color: #006485 !important;
    color: #e0e0db !important;
}

.tdc-table .table-row:hover .rdg-cell a, .tdc-table .table-row:hover .MuiLink-root.MuiLink-underlineAlways {
    color: #e0e0db !important;
}

.table-row:hover input:focus,
.table-row:hover input:focus-visible,
.table-row input {
    background: transparent !important;
    color: inherit;
    border: 1px solid transparent !important;
}

.table-sub-row {
    background-color: #777D7F;
    color: #e0e0db;
}

.table-sub-row input, .kanban-sub-task input, .kanban-sub-task #mui-component-select-team, .kanban-sub-task #mui-component-select-mag_lead {
    color: #e0e0db;
}


.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: #e1003c;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    transform: none;
}

.resizer.isResizing {
    background: #e1003c;
    opacity: 1;
    transform: none;
}

@media (hover: hover) {
    .resizer {
        opacity: 0;
        transform: none;
    }

    *:hover > .resizer {
        opacity: 1;
        transform: none;
    }
}

/*.header-sticky {*/
/*    position: sticky !important;*/
/*    left: 0;*/
/*    top: 0;*/
/*    z-index: 1;*/
/*    background-color: #006485;*/
/*}*/

/*.sticky {*/
/*    position: sticky !important;*/
/*    left: 0;*/
/*    top: 0;*/
/*    z-index: 1;*/
/*    background-color: #e0e0db;*/
/*}*/

.first-col {
    left: 0px;
}

.empty-col {
    background-color: #a1431b !important;
    color: #e0e0db !important;
    transform: translateY(0);
}

.empty-col.parent-col {
    background-color: #006485 !important;
}

.empty-col.rotated-th {
    z-index: 1;
    border-bottom: none;
    height: 120px;
}

div[aria-rowspan='2'].empty-col.rotated-th {
    z-index: 1;
    height: 100%;
    border-bottom: none;
}

.empty-col.rotated-th.rdg-cell-frozen {
    z-index: 5;
    border-bottom: none;
}

.empty-col.rotated-th .rotate-90 {
    white-space: nowrap;
}

.rotated-th {
    height: 90px;
    justify-content: center;
    white-space: break-spaces;
}

.rotate-90 {
    transform: rotate(-90deg);
    text-align: center !important;
    line-height: 1.57;
}

.header-long-txt {
    width: 100%;
    height: 100%;
    white-space: break-spaces;
    line-height: 1.57;
    display: flex;
    align-items: center;
}
.header-long-txt.key-priority.text-format{
    height: 90px;
}
.header-text-wrap {
    text-wrap: wrap;
    line-height: 1;
}

.colored-cell {
    padding: 0;
}

.td-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
}

.formula-cell {
    background-color: #c6c5c5 !important;
}

.edit-cell input[type="number"]::-webkit-outer-spin-button,
.edit-cell input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/* Firefox specific rule */
.edit-cell input[type="number"] {
    -moz-appearance: textfield;
}

.ql-container.ql-snow {
    background-color: #ffffff !important;
    min-height: 55px;
    height: auto;
}

.ql-mention-list-container {
    z-index: 1;
    background-color: #2C2F30;
    color: #c6c5c5;
}

.ql-mention-list-container ul {
    padding: 8px;
}

.ql-mention-list-container ul li {
    list-style: none;
    cursor: pointer;
    padding: 2px;
}

.ql-mention-list-container ul li:hover {
    background-color: #006485 !important;
    color: #e0e0db !important;
}

.ql-container.ql-disabled {
    padding: 0;
}

.ql-container.ql-disabled .ql-editor {
    padding: 4px;
}

.ql-editor .mention {
    background-color: #006485;
    color: #e0e0db;
    border-radius: 6px;
    height: 24px;
    margin-right: 2px;
    padding: 3px;
    user-select: all;
    line-height: 1.8 !important;
}

.ql-editor img {
    width: 100%;
}

.ql-snow .ql-tooltip {
    z-index: 1;
}

/*//reports*/
#report-panel {
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
}

#report-panel .MuiAccordionDetails-root {
    background-color: #2C2F30;
}

.drag-handle {
    cursor: grab;
}

.drag-handle:active {
    cursor: grabbing;
}

.drag-overlay {
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


#google_location_url {
    -webkit-text-fill-color: #ffffff;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 5px!important;
}